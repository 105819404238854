import React from 'react';

/**
 * A responsive logotype in SVG format. Used in header.
 */

const TbfLogo = () => (
    <>
        <svg className="header__logo-cmp d-inline-block d-lg-none" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 273.1 67.7">
            <path
                d="M31.9 47.4c0 2.7-0.3 4.9-1 6.5 -0.7 1.6-1.6 2.7-2.8 3.3 -1.2 0.6-2.7 0.9-4.6 0.9 -2.8 0-4.9-0.8-6.2-2.3 -1.3-1.5-1.9-4.2-2-8l-0.1-26.6h-6.1c-3 0-5.3-0.5-6.8-1.4C0.8 19 0 17.3 0 14.8c0-1.5 0.3-2.7 0.9-3.5 0.6-0.9 1.5-1.5 2.7-1.8 1.2-0.4 2.8-0.5 4.8-0.5H39.2c2.7 0 4.6 0.5 5.8 1.4 1.2 1 1.8 2.6 1.8 5 0 1.6-0.3 2.8-1 3.7 -0.7 0.9-1.6 1.5-2.8 1.8 -1.2 0.3-2.6 0.5-4.5 0.5h-6.7V47.4z"/>
            <path
                d="M90.7 32.6c4.5 2.6 6.7 6.2 6.7 10.6 0 1.9-0.4 3.8-1.1 5.5 -0.7 1.8-1.8 3.3-3.3 4.8 -1.5 1.4-3.4 2.6-5.8 3.4 -2.4 0.8-5.1 1.3-8.1 1.3H62.3c-2.9 0-5-0.7-6.3-2.2 -1.3-1.5-1.9-4.2-1.9-8V17.5c0-1.6 0.3-3.1 0.9-4.3 0.6-1.2 1.5-2.3 2.8-3 1.3-0.8 2.8-1.2 4.6-1.2h16.5c4.8 0 8.8 1.4 12 3.9 3.2 2.6 4.8 5.9 4.8 9.9 0 1.3-0.1 2.4-0.3 3.4s-0.5 1.9-1 2.6c-0.4 0.8-0.9 1.4-1.5 2C92.2 31.4 91.5 32 90.7 32.6zM80.9 22.8c0-2-0.8-3.4-2.3-4.1 -1.6-0.7-4.2-1.1-7.8-1.1v11c2.3 0 4.1-0.2 5.5-0.5 1.4-0.3 2.5-0.9 3.4-1.8C80.4 25.5 80.9 24.3 80.9 22.8zM81.8 42c0-1.6-0.4-2.8-1.2-3.7 -0.8-0.9-2-1.5-3.5-1.8 -1.5-0.4-3.6-0.5-6.3-0.5v12.5c3.7 0 6.5-0.5 8.4-1.4C80.9 46.1 81.8 44.4 81.8 42z"/>
            <path
                d="M121.8 48.2c0 3.3-0.7 5.7-2.2 7.5 -1.4 1.7-3.5 2.6-6.3 2.6 -2.6 0-4.6-0.8-6-2.3 -1.4-1.6-2.2-4.2-2.2-8V17.6c0-1.5 0.2-2.9 0.6-4 0.4-1.1 1-2 1.8-2.7 0.8-0.7 1.6-1.2 2.6-1.5 1-0.3 2-0.5 3.2-0.5h23.6c2.8 0 4.9 0.4 6.2 1.3 1.4 0.9 2.1 2.4 2.1 4.7 0 2.1-0.6 3.6-1.8 4.4 -1.2 0.8-3.1 1.3-5.8 1.3h-15.9v8.4h12.7c1.3 0 2.4 0.1 3.3 0.3 0.9 0.2 1.6 0.5 2.2 1 0.6 0.5 0.9 1 1.2 1.7 0.2 0.6 0.3 1.5 0.3 2.5 0 1.6-0.6 2.8-1.8 3.5 -1.2 0.7-3.1 1-5.8 1h-12L121.8 48.2 121.8 48.2z"/>
            <path
                d="M249.6 48.2c0 3.3-0.7 5.7-2.2 7.5 -1.4 1.7-3.5 2.6-6.3 2.6 -2.6 0-4.6-0.8-6-2.3 -1.4-1.6-2.2-4.2-2.2-8V17.6c0-1.5 0.2-2.9 0.6-4 0.4-1.1 1-2 1.8-2.7 0.8-0.7 1.6-1.2 2.6-1.5 1-0.3 2-0.5 3.2-0.5h23.6c2.8 0 4.9 0.4 6.2 1.3 1.4 0.9 2.1 2.4 2.1 4.7 0 2.1-0.6 3.6-1.8 4.4 -1.2 0.8-3.1 1.3-5.8 1.3h-15.9v8.4h12.7c1.3 0 2.4 0.1 3.3 0.3 0.9 0.2 1.6 0.5 2.2 1 0.6 0.5 0.9 1 1.2 1.7 0.2 0.6 0.3 1.5 0.3 2.5 0 1.6-0.6 2.8-1.8 3.5s-3.1 1-5.8 1h-12v9.3H249.6z"/>
            <path
                d="M215.2 8.2c3.8 7.9 2.8 18.3-3 31.3 -4.1 9-10 18.4-17.8 28.2 -0.6-2.1-1.3-4.1-2.2-5.8 -2.2-4.6-5.7-8-10.4-10.3 -4.2-1.7-8.3-3.3-12.5-5 -4.7-2.2-8.1-5.5-10.2-9.8 -2.1-4.3-2.3-8.4-0.8-12.6 1.5-4.1 4.4-7.2 8.7-9.3 2.9-1.4 6-2 9.3-1.7 3.5 0.3 6.3 1.7 8.4 4 0.1-7.3 3.1-12.4 9-15.3 3.9-1.9 8-2.3 12.2-1.2C210.2 1.8 213.4 4.3 215.2 8.2z"/>
        </svg>
        <svg className="header__logo-cmp d-none d-lg-inline-block" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 406.8 25.7">
            <path
                d="M293.2 3.1c1.5 3 1.1 6.9-1.2 11.9 -1.5 3.4-3.8 7-6.8 10.7 -0.2-0.8-0.5-1.5-0.8-2.2 -0.8-1.7-2.2-3-4-3.9 -1.6-0.6-3.2-1.3-4.7-1.9 -1.8-0.8-3.1-2.1-3.9-3.7 -0.8-1.6-0.9-3.2-0.3-4.8 0.6-1.6 1.7-2.7 3.3-3.5 1.1-0.5 2.3-0.8 3.5-0.6 1.3 0.1 2.4 0.6 3.2 1.5 0.1-2.8 1.2-4.7 3.4-5.8 1.5-0.7 3-0.9 4.6-0.5C291.3 0.7 292.5 1.6 293.2 3.1z"/>
            <path
                d="M12.6 19.6c0 1.1-0.1 1.9-0.4 2.6 -0.3 0.6-0.6 1.1-1.1 1.3 -0.5 0.2-1.1 0.4-1.8 0.4 -1.1 0-1.9-0.3-2.4-0.9 -0.5-0.6-0.8-1.7-0.8-3.2L6 9.3H3.6c-1.2 0-2.1-0.2-2.7-0.6S0 7.7 0 6.7c0-0.6 0.1-1.1 0.4-1.4 0.2-0.3 0.6-0.6 1.1-0.7 0.5-0.1 1.1-0.2 1.9-0.2h12.2c1.1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.7 1 0.7 2 0 0.6-0.1 1.1-0.4 1.5 -0.3 0.4-0.6 0.6-1.1 0.7 -0.5 0.1-1 0.2-1.8 0.2h-2.6V19.6z"/>
            <path
                d="M29.8 16.2c0-1.1-0.4-1.6-1.3-1.6 -0.2 0-0.3 0-0.4 0.1 -0.1 0-0.2 0.1-0.3 0.1 -0.1 0.1-0.2 0.1-0.3 0.2 -0.1 0.1-0.2 0.2-0.2 0.3 -0.1 0.1-0.1 0.3-0.1 0.4 0 0.2 0 0.4 0 0.6v3.1c0 1.6-0.3 2.7-0.8 3.3 -0.5 0.7-1.3 1-2.3 1 -1.1 0-1.8-0.3-2.3-0.9 -0.5-0.6-0.7-1.7-0.7-3.2L20.9 7.7c0-1.2 0.3-2 0.9-2.6 0.6-0.5 1.3-0.8 2.3-0.8 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5v4.1c1.4-0.9 3.1-1.3 5.1-1.3 0.9 0 1.6 0.2 2.2 0.7 0.6 0.5 1 1.2 1.2 2.2 0.3 1 0.4 2.3 0.4 4 0 0.8 0 1.6 0 2.2s-0.1 1.2-0.1 1.7c-0.1 0.5-0.1 0.9-0.3 1.2 -0.1 0.3-0.2 0.6-0.4 0.8 -0.2 0.2-0.3 0.4-0.6 0.5s-0.5 0.2-0.8 0.3c-0.3 0.1-0.6 0.1-1 0.1 -1.1 0-1.8-0.2-2.3-0.6 -0.5-0.4-0.7-1-0.7-1.8L29.8 16.2z"/>
            <path
                d="M46.5 24c-2.3 0-4.2-0.6-5.7-1.8 -1.5-1.2-2.3-2.8-2.3-4.9 0-2.2 0.7-4 2.2-5.3 1.5-1.3 3.4-1.9 5.8-1.9 2.2 0 3.8 0.4 4.9 1.2 1 0.8 1.6 1.9 1.6 3.2 0 0.3 0 0.5-0.1 0.8 -0.1 0.2-0.2 0.5-0.3 0.7 -0.1 0.2-0.3 0.5-0.6 0.7s-0.5 0.4-0.8 0.5c-0.3 0.2-0.7 0.3-1.1 0.4 -0.4 0.1-0.9 0.1-1.3 0.1l-4 0c0.2 0.6 0.5 1.1 1 1.4s1.1 0.5 1.9 0.5c0.8 0 1.7-0.1 2.5-0.3 0.4-0.1 0.8-0.2 1.2-0.5 0.4-0.2 0.7-0.3 0.9-0.3 0.3 0 0.6 0.1 0.8 0.3 0.2 0.2 0.3 0.4 0.3 0.7 0 0.3-0.2 0.8-0.5 1.2 -0.3 0.5-0.7 1-1.3 1.5 -0.5 0.5-1.3 0.9-2.1 1.2C48.4 23.9 47.5 24 46.5 24zM44.5 15.9c1.3 0 2 0 2.1 0 0.3 0 0.5-0.2 0.6-0.5 0.1-0.2 0.1-0.6 0.1-1 0-1.2-0.4-1.8-1.1-1.8 -0.3 0-0.5 0.1-0.8 0.4 -0.3 0.3-0.5 0.7-0.7 1.2C44.7 14.7 44.6 15.3 44.5 15.9z"/>
            <path
                d="M78.5 13.7c1.8 1 2.6 2.4 2.6 4.2 0 0.8-0.1 1.5-0.4 2.2s-0.7 1.3-1.3 1.9c-0.6 0.6-1.3 1-2.3 1.4 -0.9 0.3-2 0.5-3.2 0.5h-6.6c-1.2 0-2-0.3-2.5-0.9 -0.5-0.6-0.8-1.6-0.8-3.2V7.8c0-0.6 0.1-1.2 0.3-1.7 0.2-0.5 0.6-0.9 1.1-1.2 0.5-0.3 1.1-0.5 1.8-0.5h6.5c1.9 0 3.5 0.5 4.7 1.6 1.3 1 1.9 2.3 1.9 3.9 0 0.5 0 1-0.1 1.4 -0.1 0.4-0.2 0.7-0.4 1 -0.2 0.3-0.4 0.6-0.6 0.8C79.1 13.3 78.8 13.5 78.5 13.7zM74.6 9.9c0-0.8-0.3-1.3-0.9-1.6 -0.6-0.3-1.6-0.4-3.1-0.4v4.4c0.9 0 1.6-0.1 2.2-0.2 0.6-0.1 1-0.4 1.3-0.7C74.4 10.9 74.6 10.5 74.6 9.9zM75 17.5c0-0.6-0.2-1.1-0.5-1.4 -0.3-0.3-0.8-0.6-1.4-0.7 -0.6-0.1-1.4-0.2-2.5-0.2v4.9c1.5 0 2.6-0.2 3.3-0.6C74.6 19.1 75 18.4 75 17.5z"/>
            <path
                d="M88.8 24c-1.5 0-2.7-0.6-3.7-1.8 -1-1.2-1.5-2.9-1.5-5 0-2.2 0.5-3.9 1.5-5.2 1-1.3 2.2-1.9 3.7-1.9 1.5 0 3 0.5 4.3 1.6 0.5-0.9 1.3-1.4 2.5-1.4 1 0 1.8 0.2 2.4 0.7 0.6 0.4 0.9 1.3 0.9 2.5v7.4c0 1.1-0.2 1.9-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.3 0-2.1-0.4-2.5-1.1C91.8 23.6 90.4 24 88.8 24zM92.6 14.4c-0.3-0.5-0.7-0.8-1.3-0.8 -0.5 0-0.9 0.4-1.2 1.1s-0.5 1.5-0.5 2.5c0 0.9 0.1 1.7 0.4 2.3s0.7 1 1.2 1c0.5 0 1-0.3 1.3-0.8V14.4z"/>
            <path
                d="M108.1 10.1c1.8 0 3.2 0.4 4.4 1.1 1.2 0.7 1.8 1.5 1.8 2.4 0 0.2-0.1 0.5-0.3 0.7 -0.2 0.2-0.5 0.3-0.8 0.3 -0.3 0-0.6-0.1-0.9-0.3 -0.3-0.2-0.7-0.4-1-0.6s-0.7-0.4-1.2-0.6c-0.5-0.2-1-0.3-1.6-0.3 -0.7 0-1.1 0.2-1.1 0.7 0 0.3 0.2 0.6 0.6 0.8 0.4 0.2 0.9 0.4 1.7 0.6 0.7 0.2 1.3 0.4 1.7 0.6 1.4 0.6 2.4 1.1 2.9 1.8 0.5 0.6 0.8 1.5 0.8 2.5 0 0.5-0.1 1-0.4 1.5 -0.3 0.5-0.7 1-1.3 1.4 -0.6 0.4-1.3 0.8-2.3 1.1 -1 0.3-2.1 0.4-3.3 0.4 -2.4 0-4.3-0.6-5.5-1.7 -0.6-0.5-0.9-1.1-0.9-1.7 0-0.4 0.1-0.6 0.4-0.9 0.2-0.2 0.6-0.3 1-0.3s1.1 0.2 2 0.6c0.9 0.4 1.7 0.6 2.5 0.6 1 0 1.6-0.3 1.6-0.8 0-0.3-0.3-0.5-0.8-0.6 -0.6-0.2-1.3-0.4-2.2-0.6 -0.9-0.2-1.6-0.5-2.1-0.9 -0.8-0.4-1.4-0.8-1.7-1.4 -0.3-0.5-0.5-1.2-0.5-2 0-1.3 0.6-2.4 1.9-3.3C104.5 10.5 106.1 10.1 108.1 10.1z"/>
            <path
                d="M124.9 24c-2.3 0-4.2-0.6-5.7-1.8 -1.5-1.2-2.3-2.8-2.3-4.9 0-2.2 0.7-4 2.2-5.3s3.4-1.9 5.8-1.9c2.2 0 3.8 0.4 4.9 1.2 1 0.8 1.6 1.9 1.6 3.2 0 0.3 0 0.5-0.1 0.8 -0.1 0.2-0.2 0.5-0.3 0.7 -0.1 0.2-0.3 0.5-0.6 0.7s-0.5 0.4-0.8 0.5c-0.3 0.2-0.7 0.3-1.1 0.4 -0.4 0.1-0.9 0.1-1.3 0.1l-4 0c0.2 0.6 0.5 1.1 1 1.4s1.1 0.5 1.9 0.5c0.8 0 1.7-0.1 2.5-0.3 0.4-0.1 0.8-0.2 1.2-0.5 0.4-0.2 0.7-0.3 0.9-0.3 0.3 0 0.6 0.1 0.8 0.3 0.2 0.2 0.3 0.4 0.3 0.7 0 0.3-0.2 0.8-0.5 1.2 -0.3 0.5-0.7 1-1.3 1.5 -0.5 0.5-1.3 0.9-2.1 1.2S126 24 124.9 24zM123 15.9c1.3 0 2 0 2.1 0 0.3 0 0.5-0.2 0.6-0.5 0.1-0.2 0.1-0.6 0.1-1 0-1.2-0.4-1.8-1.1-1.8 -0.3 0-0.5 0.1-0.8 0.4 -0.3 0.3-0.5 0.7-0.7 1.2S123.1 15.3 123 15.9z"/>
            <path
                d="M140.3 11.5c1.3-0.9 2.6-1.4 4.1-1.4 1.5 0 2.7 0.6 3.7 1.9 1 1.3 1.5 2.8 1.5 4.8 0 2.3-0.7 4.1-2.2 5.4 -1.5 1.3-3.4 1.9-5.8 1.9 -1.5 0-2.7-0.2-3.8-0.6 -1.1-0.4-1.9-1-2.6-1.9 -0.6-0.8-0.9-1.8-1-3L134.1 7.8c0-1.2 0.3-2 0.9-2.6 0.6-0.5 1.3-0.8 2.3-0.8 1.1 0 1.8 0.3 2.3 0.8 0.5 0.5 0.8 1.3 0.8 2.4V11.5zM140.4 19.5c0.3 0.6 0.8 1 1.4 1 0.5 0 0.9-0.3 1.2-1 0.3-0.6 0.4-1.4 0.4-2.2 0-0.5 0-0.9-0.1-1.3 -0.1-0.4-0.2-0.8-0.3-1.2 -0.1-0.4-0.3-0.6-0.5-0.9 -0.2-0.2-0.4-0.3-0.7-0.3 -0.6 0-1.1 0.4-1.4 1.1 0 0.2 0 1 0 2.1C140.4 17.9 140.4 18.9 140.4 19.5z"/>
            <path
                d="M157 24c-1.5 0-2.7-0.6-3.7-1.8 -1-1.2-1.5-2.9-1.5-5 0-2.2 0.5-3.9 1.5-5.2s2.2-1.9 3.7-1.9c1.5 0 3 0.5 4.3 1.6 0.5-0.9 1.3-1.4 2.5-1.4 1 0 1.8 0.2 2.4 0.7 0.6 0.4 0.9 1.3 0.9 2.5v7.4c0 1.1-0.2 1.9-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.3 0-2.1-0.4-2.5-1.1C160.1 23.6 158.7 24 157 24zM160.9 14.4c-0.3-0.5-0.7-0.8-1.3-0.8 -0.5 0-0.9 0.4-1.2 1.1C158.2 15.4 158 16.3 158 17.2c0 0.9 0.1 1.7 0.4 2.3 0.3 0.7 0.7 1 1.2 1 0.5 0 1-0.3 1.3-0.8V14.4z"/>
            <path
                d="M176 19.6c0 1.5-0.3 2.6-0.8 3.3 -0.5 0.7-1.3 1-2.4 1 -1.1 0-1.8-0.3-2.3-0.9 -0.5-0.6-0.7-1.7-0.7-3.2l-0.1-12c0-0.5 0.1-0.9 0.2-1.3 0.1-0.4 0.2-0.7 0.4-1 0.2-0.3 0.4-0.5 0.7-0.7 0.3-0.2 0.6-0.3 0.9-0.4 0.3-0.1 0.6-0.1 1-0.1 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5V19.6z"/>
            <path
                d="M185.1 19.6c0 1.5-0.3 2.6-0.8 3.3 -0.5 0.7-1.3 1-2.4 1 -1.1 0-1.8-0.3-2.3-0.9 -0.5-0.6-0.7-1.7-0.7-3.2l-0.1-12c0-0.5 0.1-0.9 0.2-1.3 0.1-0.4 0.2-0.7 0.4-1 0.2-0.3 0.4-0.5 0.7-0.7 0.3-0.2 0.6-0.3 0.9-0.4 0.3-0.1 0.6-0.1 1-0.1 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5V19.6z"/>
            <path
                d="M202.4 19.9c0 1.3-0.3 2.3-0.9 2.9 -0.6 0.7-1.4 1-2.5 1 -1 0-1.8-0.3-2.4-0.9 -0.6-0.6-0.9-1.7-0.9-3.1V7.8c0-0.6 0.1-1.1 0.2-1.6 0.2-0.4 0.4-0.8 0.7-1.1 0.3-0.3 0.6-0.5 1-0.6 0.4-0.1 0.8-0.2 1.3-0.2h9.3c1.1 0 1.9 0.2 2.5 0.5s0.8 1 0.8 1.8c0 0.8-0.2 1.4-0.7 1.8 -0.5 0.3-1.2 0.5-2.3 0.5h-6.3v3.3h5c0.5 0 0.9 0 1.3 0.1 0.4 0.1 0.6 0.2 0.9 0.4 0.2 0.2 0.4 0.4 0.5 0.7 0.1 0.3 0.1 0.6 0.1 1 0 0.6-0.2 1.1-0.7 1.4 -0.5 0.3-1.2 0.4-2.3 0.4h-4.8V19.9z"/>
            <path
                d="M213.7 6.3c0-0.6 0.3-1.1 0.8-1.5 0.5-0.4 1.3-0.6 2.3-0.6 2.1 0 3.1 0.7 3.1 2.2 0 0.8-0.3 1.4-0.8 1.7 -0.5 0.3-1.3 0.5-2.3 0.5 -0.9 0-1.6-0.2-2.2-0.5C214 7.7 213.7 7.1 213.7 6.3zM219.8 20.8c0 1.1-0.2 1.9-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.1 0-1.9-0.3-2.3-0.8 -0.4-0.5-0.6-1.4-0.6-2.6v-7c0-1 0.3-1.7 0.8-2.3 0.5-0.6 1.2-0.9 2.1-0.9 1 0 1.8 0.2 2.4 0.7 0.6 0.4 0.9 1.3 0.9 2.5V20.8z"/>
            <path
                d="M230.3 24c-2.3 0-4.2-0.6-5.7-1.8 -1.5-1.2-2.3-2.8-2.3-4.9 0-2.2 0.7-4 2.2-5.3 1.5-1.3 3.4-1.9 5.8-1.9 2.2 0 3.8 0.4 4.9 1.2 1 0.8 1.6 1.9 1.6 3.2 0 0.3 0 0.5-0.1 0.8 -0.1 0.2-0.2 0.5-0.3 0.7 -0.1 0.2-0.3 0.5-0.6 0.7s-0.5 0.4-0.8 0.5c-0.3 0.2-0.7 0.3-1.1 0.4 -0.4 0.1-0.9 0.1-1.3 0.1l-4 0c0.2 0.6 0.5 1.1 1 1.4s1.1 0.5 1.9 0.5c0.8 0 1.7-0.1 2.5-0.3 0.4-0.1 0.8-0.2 1.2-0.5 0.4-0.2 0.7-0.3 0.9-0.3 0.3 0 0.6 0.1 0.8 0.3 0.2 0.2 0.3 0.4 0.3 0.7 0 0.3-0.2 0.8-0.5 1.2 -0.3 0.5-0.7 1-1.3 1.5 -0.5 0.5-1.3 0.9-2.1 1.2C232.2 23.9 231.3 24 230.3 24zM228.3 15.9c1.3 0 2 0 2.1 0 0.3 0 0.5-0.2 0.6-0.5 0.1-0.2 0.1-0.6 0.1-1 0-1.2-0.4-1.8-1.1-1.8 -0.3 0-0.5 0.1-0.8 0.4 -0.3 0.3-0.5 0.7-0.7 1.2C228.5 14.7 228.4 15.3 228.3 15.9z"/>
            <path
                d="M245.6 19.6c0 1.5-0.3 2.6-0.8 3.3 -0.5 0.7-1.3 1-2.4 1 -1.1 0-1.8-0.3-2.3-0.9 -0.5-0.6-0.7-1.7-0.7-3.2l-0.1-12c0-0.5 0.1-0.9 0.2-1.3 0.1-0.4 0.2-0.7 0.4-1 0.2-0.3 0.4-0.5 0.7-0.7 0.3-0.2 0.6-0.3 0.9-0.4 0.3-0.1 0.6-0.1 1-0.1 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5V19.6z"/>
            <path
                d="M263.4 19.6c0 1.5-0.3 2.6-0.8 3.3 -0.5 0.7-1.3 1-2.4 1 -1.2 0-2-0.4-2.5-1.1 -1.2 0.9-2.7 1.3-4.5 1.3 -1.5 0-2.7-0.6-3.7-1.8s-1.5-2.9-1.5-5c0-2.2 0.5-3.9 1.5-5.2 1-1.3 2.2-1.9 3.7-1.9 1.4 0 2.6 0.4 3.8 1.3V7.8c0-1.2 0.3-2 0.9-2.6 0.6-0.6 1.3-0.8 2.3-0.8 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5V19.6zM257.2 19.8l0-5.3c-0.3-0.5-0.7-0.8-1.3-0.8 -0.3 0-0.6 0.2-0.9 0.5 -0.3 0.4-0.4 0.8-0.6 1.3 -0.1 0.5-0.2 1.1-0.2 1.7 0 0.9 0.1 1.7 0.4 2.3 0.3 0.7 0.7 1 1.2 1 0.1 0 0.2 0 0.3 0 0.1 0 0.2 0 0.3-0.1 0.1 0 0.2-0.1 0.2-0.1 0.1 0 0.1-0.1 0.2-0.2s0.1-0.1 0.2-0.2C257.1 19.9 257.1 19.8 257.2 19.8z"/>
            <path
                d="M308.3 19.9c0 1.3-0.3 2.3-0.9 2.9 -0.6 0.7-1.4 1-2.5 1 -1 0-1.8-0.3-2.4-0.9 -0.6-0.6-0.9-1.7-0.9-3.1V7.8c0-0.6 0.1-1.1 0.2-1.6 0.2-0.4 0.4-0.8 0.7-1.1 0.3-0.3 0.6-0.5 1-0.6 0.4-0.1 0.8-0.2 1.3-0.2h9.3c1.1 0 1.9 0.2 2.5 0.5 0.5 0.3 0.8 1 0.8 1.8 0 0.8-0.2 1.4-0.7 1.8 -0.5 0.3-1.2 0.5-2.3 0.5h-6.3v3.3h5c0.5 0 0.9 0 1.3 0.1 0.4 0.1 0.6 0.2 0.9 0.4 0.2 0.2 0.4 0.4 0.5 0.7 0.1 0.3 0.1 0.6 0.1 1 0 0.6-0.2 1.1-0.7 1.4 -0.5 0.3-1.2 0.4-2.3 0.4h-4.7V19.9z"/>
            <path
                d="M325 20.8c0 1.1-0.2 1.9-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.1 0-1.9-0.3-2.3-0.8 -0.4-0.5-0.6-1.4-0.6-2.6v-7c0-0.6 0.1-1.2 0.3-1.7s0.6-0.9 1-1.1c0.4-0.3 0.9-0.4 1.5-0.4 1.4 0 2.4 0.4 2.8 1.3 1.1-0.8 2.2-1.3 3.2-1.3 2 0 3 0.9 3 2.8 0 0.8-0.2 1.4-0.7 1.8 -0.4 0.5-1 0.7-1.7 0.7 -0.4 0-0.8-0.1-1-0.3 -0.3-0.2-0.5-0.3-0.7-0.5 -0.2-0.2-0.5-0.3-0.8-0.3 -0.4 0-0.7 0.1-0.9 0.4V20.8z"/>
            <path
                d="M332.2 6.3c0-0.6 0.3-1.1 0.8-1.5 0.5-0.4 1.3-0.6 2.3-0.6 2.1 0 3.1 0.7 3.1 2.2 0 0.8-0.3 1.4-0.8 1.7 -0.5 0.3-1.3 0.5-2.3 0.5 -0.9 0-1.6-0.2-2.2-0.5C332.5 7.7 332.2 7.1 332.2 6.3zM338.4 20.8c0 1.1-0.2 1.9-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.1 0-1.9-0.3-2.3-0.8 -0.4-0.5-0.6-1.4-0.6-2.6v-7c0-1 0.3-1.7 0.8-2.3 0.5-0.6 1.2-0.9 2.1-0.9 1 0 1.8 0.2 2.4 0.7 0.6 0.4 0.9 1.3 0.9 2.5V20.8z"/>
            <path
                d="M348.8 24c-2.3 0-4.2-0.6-5.7-1.8s-2.3-2.8-2.3-4.9c0-2.2 0.7-4 2.2-5.3 1.5-1.3 3.4-1.9 5.8-1.9 2.2 0 3.8 0.4 4.9 1.2 1 0.8 1.6 1.9 1.6 3.2 0 0.3 0 0.5-0.1 0.8 -0.1 0.2-0.2 0.5-0.3 0.7 -0.1 0.2-0.3 0.5-0.6 0.7s-0.5 0.4-0.8 0.5c-0.3 0.2-0.7 0.3-1.1 0.4 -0.4 0.1-0.9 0.1-1.3 0.1l-4 0c0.2 0.6 0.5 1.1 1 1.4s1.1 0.5 1.9 0.5c0.8 0 1.7-0.1 2.5-0.3 0.4-0.1 0.8-0.2 1.2-0.5 0.4-0.2 0.7-0.3 0.9-0.3 0.3 0 0.6 0.1 0.8 0.3 0.2 0.2 0.3 0.4 0.3 0.7 0 0.3-0.2 0.8-0.5 1.2 -0.3 0.5-0.7 1-1.3 1.5 -0.5 0.5-1.3 0.9-2.1 1.2C350.8 23.9 349.9 24 348.8 24zM346.9 15.9c1.3 0 2 0 2.1 0 0.3 0 0.5-0.2 0.6-0.5 0.1-0.2 0.1-0.6 0.1-1 0-1.2-0.4-1.8-1.1-1.8 -0.3 0-0.5 0.1-0.8 0.4 -0.3 0.3-0.5 0.7-0.7 1.2C347.1 14.7 346.9 15.3 346.9 15.9z"/>
            <path
                d="M366.7 16.2c0-1.1-0.4-1.6-1.3-1.6 -0.4 0-0.7 0.2-1 0.5s-0.4 0.8-0.4 1.3v4.4c0 1.2-0.2 2-0.7 2.4 -0.5 0.4-1.3 0.7-2.5 0.7 -1.1 0-1.9-0.3-2.3-0.8 -0.4-0.5-0.6-1.4-0.6-2.6v-7c0-1 0.3-1.7 0.8-2.3 0.5-0.6 1.2-0.9 2.1-0.9 0.7 0 1.3 0.1 1.8 0.3 0.5 0.2 0.9 0.6 1.1 1.1 1.5-1 3.3-1.4 5.4-1.4 1.3 0 2.3 0.5 2.9 1.6 0.6 1.1 0.9 2.8 0.9 5.2 0 1.2 0 2.2-0.1 2.9 -0.1 0.8-0.1 1.4-0.3 1.9 -0.1 0.5-0.3 0.9-0.6 1.2 -0.3 0.3-0.6 0.5-0.9 0.6 -0.4 0.1-0.8 0.2-1.4 0.2 -1.1 0-1.8-0.2-2.3-0.6 -0.5-0.4-0.7-1-0.7-1.8L366.7 16.2z"/>
            <path
                d="M390.6 19.6c0 1.5-0.3 2.6-0.8 3.3 -0.5 0.7-1.3 1-2.4 1 -1.2 0-2-0.4-2.5-1.1 -1.2 0.9-2.7 1.3-4.5 1.3 -1.5 0-2.7-0.6-3.7-1.8s-1.5-2.9-1.5-5c0-2.2 0.5-3.9 1.5-5.2 1-1.3 2.2-1.9 3.7-1.9 1.4 0 2.6 0.4 3.8 1.3V7.8c0-1.2 0.3-2 0.9-2.6 0.6-0.6 1.3-0.8 2.3-0.8 1 0 1.8 0.2 2.3 0.6 0.5 0.4 0.8 1.3 0.8 2.5V19.6zM384.4 19.8l0-5.3c-0.3-0.5-0.7-0.8-1.3-0.8 -0.3 0-0.6 0.2-0.9 0.5 -0.3 0.4-0.4 0.8-0.6 1.3 -0.1 0.5-0.2 1.1-0.2 1.7 0 0.9 0.1 1.7 0.4 2.3 0.3 0.7 0.7 1 1.2 1 0.1 0 0.2 0 0.3 0 0.1 0 0.2 0 0.3-0.1 0.1 0 0.2-0.1 0.2-0.1 0.1 0 0.1-0.1 0.2-0.2s0.1-0.1 0.2-0.2C384.3 19.9 384.4 19.8 384.4 19.8z"/>
            <path
                d="M399.9 10.1c1.8 0 3.2 0.4 4.4 1.1 1.2 0.7 1.8 1.5 1.8 2.4 0 0.2-0.1 0.5-0.3 0.7 -0.2 0.2-0.5 0.3-0.8 0.3 -0.3 0-0.6-0.1-0.9-0.3 -0.3-0.2-0.7-0.4-1-0.6 -0.3-0.2-0.7-0.4-1.2-0.6 -0.5-0.2-1-0.3-1.6-0.3 -0.7 0-1.1 0.2-1.1 0.7 0 0.3 0.2 0.6 0.6 0.8 0.4 0.2 0.9 0.4 1.7 0.6 0.7 0.2 1.3 0.4 1.7 0.6 1.4 0.6 2.4 1.1 2.9 1.8 0.5 0.6 0.8 1.5 0.8 2.5 0 0.5-0.1 1-0.4 1.5 -0.3 0.5-0.7 1-1.3 1.4 -0.6 0.4-1.3 0.8-2.3 1.1 -1 0.3-2.1 0.4-3.3 0.4 -2.4 0-4.3-0.6-5.5-1.7 -0.6-0.5-0.9-1.1-0.9-1.7 0-0.4 0.1-0.6 0.4-0.9 0.2-0.2 0.6-0.3 1-0.3s1.1 0.2 2 0.6c0.9 0.4 1.7 0.6 2.5 0.6 1 0 1.6-0.3 1.6-0.8 0-0.3-0.3-0.5-0.8-0.6 -0.6-0.2-1.3-0.4-2.2-0.6 -0.9-0.2-1.6-0.5-2.1-0.9 -0.8-0.4-1.4-0.8-1.7-1.4 -0.3-0.5-0.5-1.2-0.5-2 0-1.3 0.6-2.4 1.9-3.3C396.3 10.5 397.9 10.1 399.9 10.1z"/>
        </svg>
    </>
);

export default TbfLogo;
